import React, { Component } from 'react';
import { Get, cekLogin, DESA } from '../function/Koneksi';

// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// import { BrowserRouter as Router, Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// var images = require.context('../assets/images/', true);
// const jwt = require('jsonwebtoken');

class VerifikasiCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: {},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			jenisSurat: '',
			idSurat: '',
			nomorSurat: '',
			tanggalPenerbitan: null,
			namaPemohon: 'Tidak ada',
			dto: '',
			isValid: false,
			loading: true,
		};
	}
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================
	handleSubmit = (event) => {
		event.preventDefault();
		let cekLog = {
			username: document.getElementById('username').value,
			password: document.getElementById('password').value
		};
		//console.log(cekLog);
		let resstat = 200;

		if (navigator.onLine) {
			cekLogin('auth/login', cekLog, (res) => {
				//console.log(res);
				if (res.status === resstat) {
					sessionStorage.setItem('access_token', res.data.token);

					this.props.history.push('/'); // apa gunanya ini ?
				} else if (res.status === 401) {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Login',
						pesanAlert: 'Akun belum diverifikasi, Silahkan hubungi admin!'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Login',
						pesanAlert: 'Username atau password salah!'
					});
				}
			});
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Login',
				pesanAlert: 'Jaringan internet tidak aktif...'
			});
		}
  };
  
	componentDidMount() {
		const jenisSurat = this.props.match.params.jenis;
		const idSurat = this.props.match.params.id;

		let cek = localStorage.getItem('SET_API_URL')
		// console.log("APA ISI DIDMOUNT VERIFIKASI")
		// console.log(cek)
		if(cek==null){
			setTimeout(
				function() {
					window.location.reload();
				},
				2500
			);
		}
		else{
			this.setState({ loading: false })
		}

		this.setState({ jenisSurat, idSurat });

		let jenis_surat = '';
		let kop_surat = 'surat-keterangan';
		const label_surat = jenisSurat;

		switch (label_surat) {
			// GRUP
			// skck
			// kepemilikan rumah
			// blm memiliki rumah
			// surat jalan
			// belum menikah
			// tidak menikah
			// keramaian
			// undangan
			// kehilangan
			// pindah nikah
			// keterangan umum
			// usaha

			case 'tidak-mampu':
				jenis_surat = 'tidak-mampu';
				break;
			case 'domisili':
				jenis_surat = 'domisili';
				break;
			case 'penghasilan':
				jenis_surat = 'penghasilan';
				break;
			case 'kematian':
				jenis_surat = 'kematian';
				break;
			case 'pernah-menikah':
				jenis_surat = 'pernah-menikah';
				break;
			case 'kelahiran':
				jenis_surat = 'kelahiran';
				break;
			case 'skgr':
				jenis_surat = 'kuasa';
				break;
			case 'ahli-waris':
				jenis_surat = 'ahli-waris';
				break;
			case 'surat-kuasa':
				jenis_surat = 'kuasa';
				break;

			case 'undangan':
				kop_surat = 'surat-undangan';
				break;

			default:
				jenis_surat = 'grup';
				break;
    }
    
		Get(
			kop_surat !== 'surat-undangan' ? kop_surat + '/' + jenis_surat + '/find' : kop_surat + '/find',
			idSurat,
			(data) => {
        //console.log(data.results);
        if (data.results != null) {
          this.setState({ isValid: true })
          if (typeof data.results != 'undefined') {
            let tanggalPenerbitan = null;

						// Jika surat dicreate dari web
						if (data.results.data_status_disposisi.length === 0) {
							data.results.data_status.forEach((e, index) => {
								if (e.status.toLowerCase() === 'selesai') {
									tanggalPenerbitan = e.created_at;
								}
							});
						} else { // Jika surat dicreate dari mobile
							let statusLength = data.results.data_status.length
							tanggalPenerbitan = data.results.data_status[statusLength-1].created_at
						}
  
            if (tanggalPenerbitan != null) {
              let namaPemohon = this.state.namaPemohon
  
              if (typeof data.results.nama_pemohon != "undefined" || typeof data.results.nama != "undefined") {
                namaPemohon =
                  typeof data.results.nama_pemohon != 'undefined'
                    ? data.results.nama_pemohon
                    : data.results.nama;
              } 
  
              this.setState({
                nomorSurat: data.results.nomor,
                tanggalPenerbitan: tanggalPenerbitan,
                namaPemohon: namaPemohon,
                dto: data.results.nama_ttd
              });
            }
  
            
          }
        } else {
          this.setState({ isValid: false })
        }

        setTimeout(
          function() {
            this.setState({ style: { display: 'none' } });
          }.bind(this),
          1000
        );
        
			}
		);
	}

	render() {
		let style = this.state.style;
		const background = require('../assets/images/auth-layer.png');
		// //console.log(this.props)

		let logoAplikasi = (
      <img
				src={require('../assets/images/emasdesa.png')}
				alt=""
				style={{ maxHeight: '100px' }}
			/>
    )

	if(this.state.loading===false){
		switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
			case 'halaman kampung':
				logoAplikasi = (
							<img
								src={require('../assets/images/hk_icon.png')}
								alt=""
								style={{ maxHeight: '100px' }}
							/>
				)
				break;
			case 'amanat nagari':
				logoAplikasi = (
							<img
								src={require('../assets/images/amanat_nagari_logo.png')}
								alt=""
								style={{ maxHeight: '100px' }}
							/>
				)
				break;
			default:
				logoAplikasi = (
							<img
								src={require('../assets/images/emasdesa.png')}
								alt=""
								style={{ maxHeight: '100px' }}
							/>
				)
				break;
		}
	}

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				{/* Loader starts */}
				
				<div className="loader-wrapper" style={style}>
					<div className="loader bg-white">
						<div className="line" />
						<div className="line" />
						<div className="line" />
						<div className="line" />
						<h4>Sedang memuat data.....</h4>
					</div>
				</div>
				{/* Loader ends */}

				{/*page-wrapper Start*/}
				<div className="page-wrapper">
					<div className="container-fluid">
						{/*login page start*/}
						<div className="authentication-main">
							{this.state.loading ? (
								<div className="row">
									<div className="loader-wrapper">
										<div className="loader bg-white">
											<div className="line" />
											<div className="line" />
											<div className="line" />
											<div className="line" />
											<h4>Sedang memuat data.....</h4>
										</div>
									</div>
								</div>
							) : (
							<div className="row">
								<div className="col-md-4 p-0">
									<div
										className="auth-innerleft"
										style={{ backgroundImage: 'url(' + background + ')' }}
									>
										<div className="text-center">
											{logoAplikasi}
										</div>
									</div>
								</div>
								<div className="col-md-8 p-0">
									<div className="auth-innerright">
										<div className="authentication-box">
											<div className="text-center">
												<h3>{localStorage.getItem('JENIS_APLIKASI')}</h3>
                        
                        {this.state.isValid ? 
                          this.state.tanggalPenerbitan != null ? (
                            <img
                              src={require('../assets/images/checklist.png')}
                              alt=""
                              style={{ maxHeight: '80px', margin: '15px' }}
                            />
                          ) : (
                            <img
                              src={require('../assets/images/information-icon.png')}
                              alt=""
                              style={{ maxHeight: '80px', margin: '15px' }}
                            />
                          )
                          : (
                            <img
                              src={require('../assets/images/ask.png')}
                              alt=""
                              style={{ maxHeight: '80px', margin: '15px' }}
                            />
                          )
                        }
                        {/* <img
                          src={require( this.state.tanggalPenerbitan != null ? '../assets/images/checklist.png' : '../assets/images/information-icon.png')}
                          alt=""
                          style={{ maxHeight: '80px', margin: '15px' }}
                        /> */}
                        {this.state.isValid ? 
                          this.state.tanggalPenerbitan != null ? (
                            <>
                              <h6>{'Nomor Surat : ' + this.state.nomorSurat}</h6>
                              <h6>
                                {'Tanggal Penerbitan : ' + this.state.tanggalPenerbitan}
                              </h6>
                              <h6>{'Nama Pemohon : ' + this.state.namaPemohon}</h6>
                              <h6>{'Ditandatangani Oleh : ' + this.state.dto}</h6>
                              <h6>{'Desa : ' + DESA}</h6>
                            </>
                          ) : (
                            <h6>Surat anda belum diterbitkan</h6>
                          )
                          : (
                            <h6>Surat anda tidak ditemukan</h6>
                          )
                        }
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						</div>
						{/*login page end*/}
					</div>
				</div>
				{/*page-wrapper Ends*/}
			</div>
		);
	}
}

export default VerifikasiCode;
