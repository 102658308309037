import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/css/site.css";

import SweetAlert from "react-bootstrap-sweetalert";
// import {API_URL} from "../function/Koneksi"
// import {
//   GetMarketplace,
//   ID_DESA,
//   GetEksternal
// } from "../function/Koneksi";
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';

// koneksi
import { Post, Get, LAYANAN_DESA, GetMarketplace, ID_DESA, GetEksternal, PostDemo, GetDemo } from "../function/Koneksi";
import { DataToken } from "../function/Format";
import classnames from 'classnames';

let link_gambar = localStorage.getItem('BANNER_DASHBOARD') ? localStorage.getItem('BANNER_DASHBOARD') : null;

let LIST_LAYANAN = JSON.parse(LAYANAN_DESA);
class Dashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      paid: null,
      lisensi: null,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      layananKecamatan: [],
      perizinan: [],
      jumlahMenungguEksekusi: 0,
      jumlahSelesai: 0,
      jumlahCatatanMenungguEksekusi: 0,
      jumlahVerifikasiMenungguEksekusi: 0,
      jumlahPengaduanDiajukan: 0,
      menuUtama: "inherit",
      menuWilayah: "none",
      menuPengaturan: "none",
      menuPerangkat: "none",
      menuLaporan: "none",
      menuAdministrasi: "none",
      menuSuku: "none",
      menuLayanan: "none",
      menuPerubahanData: "none",
      active_tab_icon : '1',

			link_gambar_show: link_gambar,
			data_cek: 'Kosong',
      akun: DataToken(sessionStorage.getItem("access_token")),
      loading: true
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  getData = (fil = null) => {
    // console.log("Dapatkan Layanan")
    if (fil) {
      let filJenisSurat = {
        nama: fil
      };
      // console.log("FIL apa")
      // console.log(filJenisSurat)
      PostDemo("jenis-surat/list", null, filJenisSurat, data => {
        // console.log("SHOW JENIS SURAT")
        // console.log(data)

        if (data.results) {
          let layanan = [];
          let perizinan = [];
          this.setState({ layanan: data.results, perizinan: data.results });
          // data.results.forEach(dt => {
          //   if (dt.kategori === "Layanan Masyarakat") {
          //     layanan.push(dt);
          //   }
          //   if (dt.kategori === "Perizinan Masyarakat") {
          //     perizinan.push(dt);
          //   }
          // });
          if (this._isMounted) {
            this.setState({ layanan: data.results, perizinan: data.results });
            // this.setState({ layanan, perizinan });
          
            // For testing
            if (this.props.getLayanan) {
              this.props.getLayanan(layanan)
              this.props.getPerizinan(perizinan)
            }
          }
        }

      });
    } else {
      
      // console.log("FIL apa 2")
      // console.log(fil)
      // console.log(API_URL)
      // console.log(localStorage.getItem('SET_API_URL'))
      GetDemo("jenis-surat/list", null, data => {
        // console.log(data);
        if (data.results) {
          let layanan = [];
          let perizinan = [];
          this.setState({ layanan: data.results, perizinan: data.results });
          // data.results.forEach(dt => {
          //   if (dt.kategori === "Layanan Masyarakat") {
          //     layanan.push(dt);
          //   }
          //   if (dt.kategori === "Perizinan Masyarakat") {
          //     perizinan.push(dt);
          //   }
          // });
          if (this._isMounted) {
            this.setState({ layanan: data.results, perizinan: data.results });
            // this.setState({ layanan, perizinan });
          
            // For testing
            if (this.props.getLayanan) {
              this.props.getLayanan(layanan)
              this.props.getPerizinan(perizinan)
            }
          }
        }
      });
    }
  };

  componentDidMount() {
    this._isMounted = true

    LIST_LAYANAN = JSON.parse(localStorage.getItem("LAYANAN_DESA"));
    // console.log("Tampil Layanan Dashboard didmount")
    // console.log(this._isMounted);
    if (this._isMounted) {
      this.getData();
    }

    GetMarketplace("list-layanan-desa", ID_DESA, data => {
      // console.log(data);
      // if (this._isMounted) {
        if (data.result) {
        
          if(data.result.data_api.data_invoice!==null){
            let tgl = Date.now();
            let set_tgl = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tgl);
            let ex_tgl = set_tgl.split("/");
            let hari_ini = ex_tgl[2] + "-" + ex_tgl[0] + "-" + ex_tgl[1];
            
            let expired = data.result.data_api.data_invoice.tgl_expired;
            let ex_tgl_expired = expired.split(' ');
            let tgl_expired = ex_tgl_expired[0];
           
            let set_hari_ini = new Date(hari_ini)
            let set_expired = new Date(tgl_expired)
  
            if(set_hari_ini.getTime() > set_expired.getTime()){
              // Kondisi expired
              this.setState({ 
                lisensi: false, 
              })
            }
            else{
              // Kondisi belum expired
              this.setState({ 
                lisensi: true, 
              })
            }
          }
  
  
          if (data.result.pembayaran === 1) {
            this.setState({ 
              paid: true, 
            })
          } else {
            this.setState({ 
              paid: false,
            })
          }
          this.setState({ loading: false })
          
          // For testing
          // console.log('tes paid info');
          if (this.props.getPaidInfo) {
            // console.log('tes masuk');
            this.props.getPaidInfo(data)
          }
        }
      // }
    })

    Get("keldes/find/"+ID_DESA, null, lihat => {
      if(lihat.results){
        GetEksternal("pelayanan-instansi/desa/Kecamatan/"+ lihat.results.id_kecamatan + '/Kantor Camat', null, dtkat => {
          if (dtkat.results) {
            this.setState({ layananKecamatan: dtkat.results })
          }
        });
      }
    });

    Get("list-pengajuan-layanan/menunggu-eksekusi", null, dtkat => {
      if (this._isMounted) {
        if (dtkat.results) {
          this.setState({ jumlahMenungguEksekusi: dtkat.results.total });
        }
      }
    });

    Get("list-pengajuan-layanan/selesai", null, dtkat => {
      // console.log('total selesai luar')
      // console.log(dtkat)
      // if (this._isMounted) {
        if (dtkat.results) {
          // console.log('total selesai')
          // console.log(dtkat)
          if (this._isMounted) {
            this.setState({ jumlahSelesai: dtkat.results.total });
          }
        }
      // }
    });

    Get("pengaduan?status=Diajukan", null, dtkat => {
      // console.log('total ajukan luar')
      // console.log(dtkat)
      // if (this._isMounted) {
        if (dtkat.results) {
          // console.log('total diajukan')
          // console.log(dtkat)
          if (this._isMounted) {
            this.setState({ jumlahPengaduanDiajukan: dtkat.results.total });
          }
        }
      // }
    });

    Get("catatan-penduduk?status=0", null, dtkat => {
      // console.log('total catatan luar')
      // console.log(dtkat)
      // if (this._isMounted) {
        if (dtkat.results) {
          // console.log('total catatan')
          // console.log(dtkat)
          if (this._isMounted) {
            this.setState({ jumlahCatatanMenungguEksekusi: dtkat.results.total });
          }
        }
      // }
    });

    Get("pengguna?status=1", null, dtkat => {
      if (dtkat.results) {
        if (this._isMounted) {
          // console.log('total pengguna')
          // console.log(dtkat)
          this.setState({
            jumlahVerifikasiMenungguEksekusi: dtkat.results.total
          });
        }
      }
    });

  }

  componentWillUnmount(){
    this._isMounted = false
  }

  render() {
    const tampilLayanan = this.state.layanan.map(dt => {
      let symbol = "";
      let judul = "";
      let condition = false;

      switch (dt.nama.toLowerCase()) {
        case "surat keterangan tidak mampu":
          symbol = "TM";
          judul = "Tidak Mampu";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.tidak_mampu) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_tidak_mampu) {
            condition = true;
          }
          break;

        case "surat keterangan domisili":
          symbol = "D";
          judul = "Domisili";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.domisili) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_domisili) {
            condition = true;
          }
          break;

        case "surat keterangan domisili usaha":
          symbol = "DU";
          judul = "Domisili Usaha";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.domisili_usaha) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_domisili_usaha) {
            condition = true;
          }
          break;

        case "surat keterangan penghasilan":
          symbol = "P";
          judul = "Penghasilan";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.penghasilan) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_penghasilan) {
            condition = true;
          }
          break;

        case "surat keterangan kelahiran":
          symbol = "KL";
          judul = "Kelahiran";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.kelahiran) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_kelahiran) {
            condition = true;
          }
          break;

        case "surat keterangan kematian":
          symbol = "KM";
          judul = "Kematian";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.kematian) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_kematian) {
            condition = true;
          }
          break;

        case "surat keterangan ahli waris":
          symbol = "AW";
          judul = "Ahli Waris";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.ahli_waris) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_ahli_waris) {
            condition = true;
          }
          break;

        case "surat keterangan kepemilikan rumah":
          symbol = "KPR";
          judul = "Kepemilikan Rumah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.kepemilikan_rumah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_kepemilikan_rumah) {
            condition = true;
          }
          break;

        case "surat keterangan belum memiliki rumah":
          symbol = "BMR";
          judul = "Belum Memiliki Rumah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.belum_memiliki_rumah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_belum_memiliki_rumah) {
            condition = true;
          }
          break;

        case "surat keterangan usaha":
          symbol = "U";
          judul = "Usaha";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.usaha) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_usaha) {
            condition = true;
          }
          break;

        case "surat kuasa":
          symbol = "SK";
          judul = "Surat Kuasa";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.kuasa) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_kuasa) {
            condition = true;
          }
          break;

        case "surat kuasa skgr":
          symbol = "SKGR";
          judul = "Surat SKGR";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.skgr) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_kuasa_skgr) {
            condition = true;
          }
          break;

        case "surat pengantar skck":
          symbol = "SKCK";
          judul = "Pengantar SKCK";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.skck) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_pengantar_skck) {
            condition = true;
          }
          break;

        case "surat pernyataan kehilangan":
          symbol = "PH";
          judul = "Pernyataan Kehilangan";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.kehilangan) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_pernyataan_kehilangan) {
            condition = true;
          }
          break;

        case "surat keterangan jalan":
          symbol = "SJ";
          judul = "Surat Jalan";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.jalan) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_jalan) {
            condition = true;
          }
          break;

        case "surat pernyataan tidak menikah":
          symbol = "TN";
          judul = "Tidak Nikah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.tidak_menikah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_pernyataan_tidak_menikah) {
            condition = true;
          }
          break;

        case "surat keterangan pernah menikah":
          symbol = "PN";
          judul = "Pernah Nikah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.pernah_menikah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_pernah_menikah) {
            condition = true;
          }
          break;

        case "surat pernyataan belum menikah":
          symbol = "BN";
          judul = "Belum Nikah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.belum_menikah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_pernyataan_belum_menikah) {
            condition = true;
          }
          break;

        case "surat pengantar pindah nikah":
          symbol = "PIN";
          judul = "Pindah Nikah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.pindah_nikah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_pengantar_pindah_nikah) {
            condition = true;
          }
          break;

        case "surat keterangan pindah datang wni":
          symbol = "PDW";
          judul = "Pindah Datang WNI";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.pindah_datang_wni) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_pindah_datang_wni) {
            condition = true;
          }
          break;

        case "surat izin keramaian":
          symbol = "IK";
          judul = "Izin Keramaian";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.keramaian) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_izin_keramaian) {
            condition = true;
          }
          break;

        case "surat keterangan":
          symbol = "UM";
          judul = "Umum";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.umum) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan) {
            condition = true;
          }
          break;

        case "surat undangan":
          symbol = "UN";
          judul = "Undangan";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.undangan) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_undangan) {
            condition = true;
          }
          break;

        case "surat keterangan penyaksian tanah":
          symbol = "PT";
          judul = "Penyaksian Tanah";

          // if (LIST_LAYANAN !== null && LIST_LAYANAN.penyaksian_tanah) {
          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_penyaksian_tanah) {
            condition = true;
          }
          break;

        // case "surat keterangan penyaksian tanah":
        //   symbol = "PO";
        //   judul = "Penghasilan Orangtua";

        //   if (LIST_LAYANAN !== null && LIST_LAYANAN.penghasilan_orangtua) {
        //     condition = true;
        //   }
        //   break;

        case "surat keterangan penghasilan orang tua":
          symbol = "PO";
          judul = "Penghasilan Orang Tua";

          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_penghasilan_orang_tua) {
            condition = true;
          }
          break;

        case "surat keterangan penguburan":
          symbol = "PR";
          judul = "Penguburan";

          if (LIST_LAYANAN !== null && LIST_LAYANAN.surat_keterangan_penguburan) {
            condition = true;
          }
          break;

        default:
          break;
      }
      
      let link = dt.nama.toLowerCase().replace(/ /g, "-");
      let toData = {
        pathname: "/admin/" + link, state: dt
      }

      if (!this.state.paid) {
        toData = {
          pathname: "/admin/layanan/join-us"
        }
      }

      if (this.state.paid === null && this.state.lisensi === null) {
        toData = {
          pathname: "/admin/layanan/join-us"
        }
      }

      if (this.state.paid && this.state.lisensi === null) {
        toData = {
          pathname: "/admin/layanan/belum-setting"
        }
      }

      if (this.state.paid && this.state.lisensi === false) {
        toData = {
          pathname: "/admin/layanan/expired"
        }
      }

      if (condition) {
        return (
          <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
            <Link
             data-testid={"card|" + dt.nama.toLowerCase()}
             onClick={()=> {
               if (this.props.clickCardSurat) {
                 this.props.clickCardSurat()
               }
             }}
             to={toData}>
              <div className="dashboard-over">
                <b>
                  <span>{symbol}</span>
                  <h6>{judul}</h6>
                </b>
              </div>
            </Link>
          </div>
        );
      } else {
        return "";
      }
    });

    const tampilLayananKecamatan = (
			// <div key="listKecamatan">
      <>
      {
        ID_DESA === '1409012016'
        ?
          <>
          <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
            <Link to={{ pathname: '/admin/layanan/kecamatan/pindah-datang'}}>
              <div className="dashboard-over">
                  <b>
                  <span>PD</span>
                  <h6>Penerbitan Pindah/Datang Keluar Daerah</h6>
                  </b>
              </div>
            </Link>
          </div>
          </>
        : ''
      }
				{this.state.layananKecamatan.map((val, i) => {
					let pathname = ''
					let inisial = ''
          // console.log('data list eksternal')
          // console.log(val)
					switch (val.data_pelayanan.nama.toLowerCase()) {
						case 'surat izin usaha mikro kecil':
							pathname = '/admin/layanan/kecamatan/iumk'
							inisial = 'IUM'
							break;
						case 'surat rekomendasi izin usaha':
							pathname = '/admin/layanan/kecamatan/rekomendasi-usaha'
							inisial = 'RIU'
							break;
            default:
              // no default
              break;
					}

          if (pathname !== '') {
            return (
              <div key={`layanan-kecamatan-${i}`} className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
                <Link to={{ pathname, state: val }}>
                  <div className="dashboard-over">
                    <b>
                      <span>{inisial}</span>
                      <h6>{val.data_pelayanan.nama}</h6>
                    </b>
                  </div>
                </Link>
              </div>
            )
          } else {
            return ''
          }

				})}
      </>
			// </div>
		);

    let bannerAplikasi = null
    // if(this.state.data_cek === "Kosong"){
    if(this.state.link_gambar_show === null){
      bannerAplikasi = 
      (  
        <img
          src={require("../assets/images/banner/kampung-tengah.png")}
          alt=""
          style={{
            maxHeight: "250px",
            width: "100%",
            borderRadius: "10px"
          }}
        />
      )
    }
    else{
      bannerAplikasi = 
      (  
        <img
          src={localStorage.getItem('BANNER_DASHBOARD')}
          alt=""
          style={{
            maxHeight: "250px",
            width: "100%",
            borderRadius: "10px"
          }}
        />
      )
    }
    

    // switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
    //   case 'halaman kampung':
    //     bannerAplikasi = (
    //       <img
    //         src={require("../assets/images/banner/sungai-petai.png")}
    //         alt=""
    //         style={{
    //           maxHeight: "250px",
    //           width: "100%",
    //           borderRadius: "10px"
    //         }}
    //       />
    //     )
    //     break;
    // }

    if (this.state.loading) {
      return (
        <div className="row" data-testid="divLoadingDashboard">
          <div className="col-sm-12" align="center">
            <img 
            src={require("../assets/images/loading2.gif")} 
            alt="loading"
            style={{
              // width: "100%",
              borderRadius: "10px",
              width: '331px'
            }}/> 
          </div>
        </div>
      )
    } else {
      
    return (
      <div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>
        {this.state.alert}
        <div className="container-fluid" data-testid="dashboard-digidesa">
          {/* <div className="row"> */}
          {this.state.akun.sub.data_role.nama_role === "Admin" ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="card layanan-baru">
                      <div className="card-body">
                        {this.state.paid !== null ? (
                          <div>
                            <Nav tabs className="tabs-color">
                              <NavItem style={{ cursor: 'pointer' }}>
                                <NavLink
                                  className={classnames({
                                    active: this.state.active_tab_icon === '1'
                                  })}
                                  onClick={() => {
                                    this.setState({ active_tab_icon: '1' })
                                  }}
                                >
                                  <i className="icofont icofont-ui-home" />
                                  {this.state.paid ? "Desa" : "Kecamatan"}
                                </NavLink>
                              </NavItem>
                              <NavItem style={{ cursor: 'pointer' }}>
                                <NavLink
                                  className={classnames({
                                    active: this.state.active_tab_icon === '2'
                                  })}
                                  onClick={() => {
                                    this.setState({ active_tab_icon: '2' })
                                  }}
                                >
                                  <i className="icofont icofont-ui-home" />
                                  {this.state.paid ? "Kecamatan" : "Desa"}
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.active_tab_icon}>
                              <TabPane tabId="1">
                                {this.state.paid ? (
                                  <div key={ Math.random().toString(36).substr(2, 9) } className="row pt-4">
                                    {tampilLayanan}
                                  </div>
                                ) : (
                                  <div key={ Math.random().toString(36).substr(2, 9) } className="row pt-4">{tampilLayananKecamatan}</div>
                                )}
                              </TabPane>
                              <TabPane tabId="2">
                                {this.state.paid ? (
                                  <div key={ Math.random().toString(36).substr(2, 9) } className="row pt-4">{tampilLayananKecamatan}</div>
                                ) : (
                                  <div key={ Math.random().toString(36).substr(2, 9) } className="row pt-4">{tampilLayanan}</div>
                                )}
                              </TabPane>
                            </TabContent>
                          </div>
                        ) : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="card" style={{ borderRadius: "10px" }}>
                          <div className="text-center">
                            {bannerAplikasi}
                          </div>
                        </div>
                      </div>
                      {/* pengajuan selesai */}
                      <div className="col-sm-12 col-md-12">
                        <Link
                          to={{
                            pathname: this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/disposisi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null &&this.state.lisensi === false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us',
                            tabProps: "5"
                          }}
                        >
                          <div className="pengajuan-selesai">
                            <b>
                              <span>
                                {/* <b>24%</b> */}
                                {this.state.jumlahSelesai}
                              </span>
                              <h4>Pengajuan Selesai</h4>
                              {/* <span>100 Pengajuan</span> */}
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* pengajuan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname: this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/disposisi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null &&this.state.lisensi === false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us',
                            tabProps: "2"
                          }}
                        >
                          <div className="pengajuan-menunggu-eksekusi">
                            <b>
                              <span>{this.state.jumlahMenungguEksekusi}</span>
                              <h4>Pengajuan</h4>
                              <h6>Menunggu Eksekusi</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* pengaduan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengaduan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahPengaduanDiajukan}</span>
                            <h4>Pengaduan</h4>
                            <h6>Diajukan</h6>
                          </b>
                        </div>
                      </div>
                      {/* catatan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname: this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/catatan" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null &&this.state.lisensi === false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us',
                            tabProps: "1"
                          }}
                        >
                          <div className="catatan-menunggu-eksekusi">
                            <b>
                              <span>
                                {this.state.jumlahCatatanMenungguEksekusi}
                              </span>
                              <h4>Catatan</h4>
                              <h6>Belum Selesai</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* verifikasi menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname: this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/verifikasi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null &&this.state.lisensi === false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us',
                            tabProps: "2"
                          }}
                        >
                          <div className="verifikasi-menunggu-eksekusi">
                            <b>
                              <span>
                                {this.state.jumlahVerifikasiMenungguEksekusi}
                              </span>
                              <h4>Verifikasi</h4>
                              <h6>Menunggu Verifikasi</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row" style={{ marginTop: "50px" }}>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <a
                      href="www.gallarang.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ color: "#707070", textAlign: "center" }}>
                        <img
                          src={require("../assets/images/gallarang.png")}
                          alt=""
                          style={{ maxWidth: "100%", maxHeight: "100px" }}
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <h5>
                        <b>PT. Gallarang Digital Indonesia</b>
                      </h5>
                      <h6>Beranda emasDESA Terintegrasi</h6>
                      <h6>2022 All Rights Reserved</h6>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <h5>
                        <b>Hubungi Kami</b>
                      </h5>
                      <a
                        href="tel: (0813) 5607 6674"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#707070" }}
                      >
                        <h6>(0813) 5607 6674</h6>
                      </a>
                      <a
                        href="mailto: info@gallarang.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#707070" }}
                      >
                        <h6>info@gallarang.com</h6>
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <span style={{ fontSize: "30px" }}>
                        <a
                          href="https://wa.me/6281356076674"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#707070" }}
                        >
                          <i className="icofont icofont-brand-whatsapp"></i>{" "}
                        </a>
                        <a
                          href="https://www.instagram.com/gallarie_official/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#707070" }}
                        >
                          <i className="fa fa-instagram"></i>
                        </a>{" "}
                        <a
                          href="https://www.facebook.com/gallarieofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#707070" }}
                        >
                          <i className="fa fa-facebook"></i>
                        </a>{" "}
                        <a
                          href="https://youtube.com/channel/UCYTPZs6LDXPbcwYZbaJP7XA"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#707070" }}
                        >
                          <i className="fa fa-youtube"></i>
                        </a>
                      </span>
                      <a
                        href="https://www.gallarang.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#707070" }}
                      >
                        <h6>www.gallarang.com</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    backgroundColor: "#f6f7fb"
                  }}
                >
                  <div className="card-body">
                    <div className="row">
                      {/* pengajuan selesai */}
                      <div className="col-sm-12 col-md-12">
                        <div className="pengajuan-selesai">
                          <b>
                            <span>
                              {/* <b>24%</b> */}
                              {this.state.jumlahSelesai}
                            </span>
                            <h4>Pengajuan Selesai</h4>
                            {/* <span>100 Pengajuan</span> */}
                          </b>
                        </div>
                      </div>
                      {/* pengajuan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengajuan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahMenungguEksekusi}</span>
                            <h4>Pengajuan</h4>
                            <h6>Menunggu Eksekusi</h6>
                          </b>
                        </div>
                      </div>
                      {/* pengaduan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengaduan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahPengaduanDiajukan}</span>
                            <h4>Pengaduan</h4>
                            <h6>Diajukan</h6>
                          </b>
                        </div>
                      </div>
                      {/* catatan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="catatan-menunggu-eksekusi">
                          <b>
                            <span>
                              {this.state.jumlahCatatanMenungguEksekusi}
                            </span>
                            <h4>Catatan</h4>
                            <h6>Belum Selesai</h6>
                          </b>
                        </div>
                      </div>
                      {/* verifikasi menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="verifikasi-menunggu-eksekusi">
                          <b>
                            <span>
                              {this.state.jumlahVerifikasiMenungguEksekusi}
                            </span>
                            <h4>Verifikasi</h4>
                            <h6>Menunggu Verifikasi</h6>
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    );
    }
  }
}

export default Dashboard;